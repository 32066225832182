.external_link {
  padding: 0 0.3em;
  color: var(--neutral-color);
  font-weight: 700;
  position: relative;
}

.external_link:before {
  content: "";
  position: absolute;
  width: calc(100% - (0.3em *2));
  height: 1px;
  background-color: var(--neutral-color);
  bottom: -2px;
}

.social_codpen, .social_specky {
  display: inline-block;
  overflow: hidden;
  text-indent: -300px;
  width: 120px;
  height: 30px;
}

.social_codpen{
  background: url(./img/codpen.svg) center / contain no-repeat;
  background-size: 80%;
}

.social_specky {
  background: url(./img/specky.png) center / contain no-repeat;
  background-size: 75%;
}

.social_linkedin, .social_github, .social_codepen {
  display: inline-block;
  width: 40px;
  height: 40px;
  /* background-color: tomato; */
  overflow: hidden;
  text-indent: -300%;
}

.social_linkedin {
  background: url(./img/linkedin.png) center / contain no-repeat;
  background-size: 90%;
}

.social_github {
  background: url(./img/github.png) center / contain no-repeat;
  background-size: 90%;
}

.social_codepen {
  background: url(./img/codpenIo.svg) center / contain no-repeat;
  background-size: 90%;
}