.example_code{
  width: 100%;
}
.example_code pre {
	margin-bottom: 40px;
	/* border: solid 4px tomato; */
	padding-bottom: 20px !important;
	margin-bottom: 40px !important;
	position: relative;
}

.example_code pre:before {
	content: "";
	position: absolute;
	width: 100%;
	height: calc(100% + 50px);
	left: 0;
	top: 0;
	background-color: #fdfdfd;;
	z-index: -1;
	border-radius: 10px;
	transform: translateY(-40px);
	
}

.header_highlighter{
  /* background-color: #fdfdfd; */
  display: flex;
  justify-content: flex-end;
  border-radius: 10px 10px 0px 0px;
  transform: translateY(5px);
}

.copy_button {
  width: 90px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  position: relative;
  padding-left: 10px;
  cursor: pointer;
  font-size: 12px;
  color: var(--secundary-color-dark);
  font-family: 'Lato', sans-serif;
}

.copy_button::after{
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  left:0;
  top:0;
  background: url(./img/data-icon.svg) center / contain no-repeat;
  background-size: 55%;
}

.copied  {
  width: 90px;
  height: 30px;
  font-size: 12px;
  color: var(--secundary-color-dark);
  position: relative;
  /* background-color: #4c5b7d; */
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copied::after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  left:0;
  top:0;
  background: url(./img/check-icon.svg) center / contain no-repeat;
  background-size: 45%;
}

.item {
  border: solid 1px rgba(62, 71, 105, 0.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.item p {
  color: rgba(62, 71, 105, 0.3);
  padding-bottom: inherit;
}

.example{ 
	margin-bottom: 20px;
}

div[class*="exemplo_"] {
  background: var(--neutral-color-02);
  margin-bottom: 24px;
  padding: 10px;
}

/* DEFININDO O GRID */
/*01*/
.exemplo_1{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 100px 100px;
	grid-gap: 10px;	
}

/*02*/
.exemplo_2{
	display: grid;
	grid-template-columns: 1fr 1fr 2fr;
	grid-template-rows: 100px 100px;
	grid-gap: 10px;	
}

/*03*/
.exemplo_3{
	display: grid;
	grid-template-columns: 30px 1fr 2fr;
	grid-template-rows: 100px 100px;
	grid-gap: 10px;	
}

/*04*/
.exemplo_4{
	display: grid;
	grid-template-columns: repeat(2, 2fr) 1fr;
	grid-template-rows: 100px 100px;
	grid-gap: 10px;	
}

/*05*/
.exemplo_5{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
}

/*06*/
.exemplo_6{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 100px;
	grid-gap: 10px;
}

.resultado { 
  overflow: hidden;
}

.resize{
  max-width: 100%;
  min-height: 10px;
  resize: horizontal;
  overflow: auto;
}

/*07*/
.exemplo_7{
	display: grid;
	grid-template-columns: repeat(auto-fill, 100px);
	grid-auto-rows: 100px;
	grid-gap: 10px;
}

/*08*/
.exemplo_8{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-auto-rows: 100px;
	grid-gap: 10px;
}

/*posicionando Elementos no grid */

/*09*/
.exemplo_9{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 100px;
	grid-gap: 10px;
}

/*10*/
.exemplo_10{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 100px;
	grid-gap: 10px;
}
.exemplo_10 .item:nth-child(1) {
	grid-column-start: 2;
	grid-column-end: 4;
	grid-row-start: 1;
	grid-row-end: 3;
}

/*11*/
.exemplo_11{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 100px;
	grid-gap: 10px;
}
.exemplo_11 .item:nth-child(1) {
	grid-column: 2 / 4;
	grid-row: 1 / 3;
}
/* usando grid-area */
/*12*/
.exemplo_12{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 100px;
	grid-gap: 10px;
}
.exemplo_12 .item:nth-child(1) {
	grid-area: 1 / 2 / 3 / 4;
}

/*usando line-names */
/*13*/
.exemplo_13{
	display: grid;
	grid-template-columns: [main-start] 1fr [content-start] 1fr [content-end] 1fr [main-end];
	grid-template-rows: [main-start] 100px [content-start] 100px [content-end] 100px [main-end];
	grid-gap: 10px;
}
.exemplo_13 .item:nth-child(1) {
	grid-row-start: content-end;
	grid-column-start: content-end;
	grid-row-end: main-end;
	grid-column-end: main-end;
	
}
.exemplo_13 .item:nth-child(2) {
	grid-area: main-start / main-start / main-end / content-start;	
}

.exemplo_13 .item:nth-child(3) {
	grid-area: content ;
}

/*usando grid-areas */
/*14*/
.exemplo_14{
	display: grid;
	grid-template-columns: repeat(3 , 1fr);
	grid-template-rows: repeat(3 , 200px);
	grid-gap: 10px;
	grid-template-areas:	" side1 main main"
							" . main main"
							" . footer footer"
}
.exemplo_14 .item:nth-child(1) {
	grid-area: side1;
	
}
.exemplo_14 .item:nth-child(2) {
	grid-area: footer;	
}

.exemplo_14 .item:nth-child(3) {
	grid-area: main;
}