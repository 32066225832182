@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lustria');

:root {
  --primary-color: #009688;

  --secundary-color-dark:#4c5b7d;
  --secundary-color-vivid:#FF5722;

  --neutral-color: #f4f2ea;
  --neutral-color-02: #d3d4d675;

  --container-width-mobile: 90vw;
  --video-height_desktop: calc( var(--container-width-mobile) * 9 / 13 )
  --nav-height: 70px
}

body {
  font-family: 'Lato', sans-serif;
  color: var(--primary-color);
  background-color: var(--neutral-color);

  width: 100vw;
  max-width: 100%;

}

h2 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2.4em;
  color: var(--neutral-color);
}

h3 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 3em;
  letter-spacing: 0.05em;
  padding-bottom: 2em;
}

h4 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.6em;
  letter-spacing: 0.05em;
  padding-bottom: 1.5em;
  line-height: 1.5em;
}

h5 {
  font-size: 1.2em;
  font-weight: 800;
  padding-bottom: 24px;
  color: #F44336;
  text-transform: uppercase;
}

p {
  font-size: 1.7em;
  line-height: 1.9em;
  padding-bottom: 2.4em;
}

p strong, li strong {
  color: var(--secundary-color-dark);
  font-weight: 900;
}

p.obs {
  background: var(--neutral-color-02);
  color: var(--secundary-color-dark);
  padding: 0 2px;
  margin-bottom: 24px;
}

.container_article > p {
  color: var(--neutral-color);
}

.glossario p{
  padding-bottom: 0;
}

p strong.codigo, li strong.codigo {
  background-color: var(--primary-color);
  color: var(--neutral-color);
  border-radius: 2px;
  font-family: consolas,"Liberation Mono",courier,monospace;
  padding: 0 8px;
  font-weight: 100;
  word-wrap: break-word;
}

ul {
  margin-bottom: 23px;
  padding-left: 48px;
}

li {
  font-size: medium;
  line-height: 1.9em;
  padding-bottom: 24px;
  position: relative;
}

li:last-of-type {
  padding-bottom: 0;
}

li:after {
	content: "";
  width: 12px;
  height: 12px;
  background: #009688;
  position: absolute;
  top: 9px;
  border-radius: 2px;
  left: -24px;
}

.container_master {
	width: var(--container-width-mobile);
	min-height: calc(100vh - var(--nav-height));
	margin: auto;
	padding-top: 80px;
	padding-bottom: 24px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto) calc(9 / 16 * var(--container-width-mobile)) auto;
  grid-gap: 20px;
}

.container_title {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  padding: 20px 20px 0 20px;
}

.container_article{
  grid-column: 1 / 5;
  grid-row: 2 / 3;
  padding: 0 20px 20px 20px;
}

.container_video{
  width: var(--container-width-mobile);
  height: calc(9 / 16 * var(--container-width-mobile));
  background-color: var(--secundary-color-dark);
  grid-column: 1 / 5;
  grid-row: 3 / 4;
}

.container_links{
  grid-column: 1 / 5;
  grid-row: 4 / 5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.background {
  background-color: var(--secundary-color-vivid);
  grid-column: 1 / 5;
  grid-row: 1 / 3;
  z-index: -1;
}

/*React Player*/
.container_thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
}

.container_thumbnail > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_thumbnail > button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: url("./img/play-button.png") center / contain no-repeat;
  border: none;
  overflow: hidden;
  text-indent: -300%;
}

.container {
	padding-top: 80px;
  width: 90vw;
  margin: auto;
}

.container_examples {
  /* background: olive; */
  /* width: 90vw;
  margin: auto; */
}

@media screen and (min-width: 1024px) {
  .container_master {
    /* width: var(--container-width-mobile);
    min-height: calc(100vh - var(--nav-height));
    margin: auto;
    padding-top: 80px;
    padding-bottom: 24px; */
    /* background-color: violet; */
  
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    /* grid-template-rows: repeat(2, auto) calc(9 / 16 * var(--container-width-mobile)) auto; */
    grid-template-rows: repeat(4, auto);
    grid-gap: 0px;
  }

  .container_title{
    /* background-color: #4c5b7d; */
    grid-column: 1 / 14;
    grid-row: 1 / 2;
  }

  .container_article {
    grid-column: 1 / 5;
    grid-row: 2 / 4;
    /* background-color: #4c5b7d;  */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 40px;
  }

  .container_video{
    grid-column: 5 / 14;
    grid-row: 1 / 4;
    width: calc( var(--container-width-mobile) * 9 / 13 );
    height: calc(( var(--container-width-mobile) * 9 / 13 ) * 9 / 16);
  }

  .container_thumbnail {
    width: calc( var(--container-width-mobile) * 9 / 13 );
    height: calc(( var(--container-width-mobile) * 9 / 13 ) * 9 / 16);
  }

  .container_links {
    grid-column: 10 / 14;
    grid-row: 4 / 5;
  }

  .background { 
    grid-column: 1 / 5;
    grid-row: 1 / 4;
    width: calc(100% - 20px);
  }
}