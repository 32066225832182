/* Hamburger icon */
.hamburger {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 10000;
  cursor: pointer;
}

.hamburger_animacao {
  width: 30px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--secundary-color-dark);
  transition: ease-in-out 0.5s;
  position: absolute;
  top: 50%;
  right: 0;
}

.hamburger_animacao::before, .hamburger_animacao::after{
  content: "";
  width: 30px;
  height: 4px;
  position: absolute;
  border-radius: 2px;
  right: 0;
  background-color: var(--secundary-color-dark);
  transition: ease-in-out 0.5s;
}

.hamburger_animacao::before {
  top: 10px;
}

.hamburger_animacao::after {
  top: -10px;
}

.hamburger.open > .hamburger_animacao {
  background-color: var(--neutral-color);
  width: 3px;
}

.hamburger.open > .hamburger_animacao::before{
  transform: rotate(-45deg);
  transform: rotate(45deg) translate(-8.67px, -6.67px);
}

.hamburger.open > .hamburger_animacao::after{
  transform: rotate(-45deg) translate(-7.67px, 5.67px);
} 

/* Header container */
.container_header {
  background-color: white;
  gap: 20px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  z-index: 999;
}

.logo {
  color: var(--secundary-color-dark);
  z-index: 999;
  font-size: 2em;
  font-weight: 300;
  text-transform: uppercase;
}

.logo span {
  font-weight: 900;
}


/* Close menu button */
.close_menu {
  font-size: 13px;
  /* font-weight: 900; */
  text-transform: uppercase;
  position: absolute;
  top: 23px;
  right: 47;
  transition: ease-in-out 0.5s;
  color: black;
}

.container_nav.open .close_menu {
  color: white;
  right: calc(10vw + 40px);
}

/* Navigation container */
.container_nav {
  background-color: var(--neutral-color);
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(-100vh);
  padding: 80px 20vw 80px 20vw;
  gap: 3em;
  overflow: hidden;
  transition: transform ease-in-out 0s;
}

.container_nav.open {
  transform: translateY(0%);
}

.container_nav > a {
  color: var(--secundary-color-dark);
  font-size: 1.7em;
  font-weight: 900;
  position: relative;
  transform: translateX(-100%);
  transition-timing-function: cubic-bezier(0,0,.2,1);
}

.info_author {
  /* background-color: tomato; */
  opacity: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}

.info_author p {
  color: var(--secundary-color-dark);
  padding-bottom: 10px;
  /* font-weight: 900; */
  font-size: 14px;
  line-height: 19px;
}

.info_author ul{ 
  display: flex;
  margin-bottom: 0px !important;
  padding-left: 0px !important;
  height: 40px;
}

.info_author ul li:after {
  content: "";
  display: none;
}

.social_nav {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}


/* menu aberto */
.container_nav.open > a:nth-of-type(1){
  transform: translateX(0%);
  transition-duration: 0.3s;
}

.container_nav.open > a:nth-of-type(2){
  transform: translateX(0%);
  transition-duration: 0.6s;
}

.container_nav.open > a:nth-of-type(3){
  transform: translateX(0%);
  transition-duration: 0.9s;
}

.container_nav.open > a:nth-of-type(4){
  transform: translateX(0%);
  transition-duration: 1.2s;
}

.container_nav.open > .info_author {
  opacity: 1;
  transition-duration: 2s;
}

.container_nav > a[class="active"]{
 color: var(--primary-color);
}